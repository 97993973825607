<template>
  <v-container>
    <v-row
      v-permission="{
        roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
        operator: '==='
      }"
    >
      <v-col class="text-right">
        <BaseButton
          color="secondary"
          title="Novo cadastro"
          icon="fa-solid fa-plus"
          @click="create"
        />
      </v-col>
    </v-row>

    <BaseTableList title="Listagem de contatos">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.phoneNumber`]="{ item }">
            {{ formatPhone(item.phoneNumber) }}
          </template>

          <template v-slot:[`item.mobileNumber`]="{ item }">
            {{ formatPhone(item.mobileNumber) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="edit(item)"
                  v-permission="{
                    roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                    operator: '==='
                  }"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  small
                  @click="deleteContact(item)"
                  v-permission="{
                    roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                    operator: '==='
                  }"
                >
                  fa-regular fa-trash-can
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />
      </v-col>
    </v-row>

    <DialogContact
      v-if="dialog"
      :dialog="dialog"
      :title="dialogTitle"
      :editMode="dialogEdit"
      :selectedItem="selectedItem"
      :insuranceCompanyDocument="$route.params.cnpj"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { confirmMessage, showMessage } from '@/helpers/messages';
import { formatPhone } from '@/helpers/formatting';
import { InsuranceCompanyService } from '@/services/api/insuranceCompany';

export default {
  components: {
    DialogContact: () => import('./dialogs/dialog-contact')
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    options: {},
    headers: [
      { text: 'Nome completo', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Telefone', value: 'phoneNumber' },
      { text: 'Celular', value: 'mobileNumber' },
      { text: '', value: 'action' }
    ],
    dialog: false,
    dialogTitle: '',
    dialogEdit: false,
    selectedItem: {}
  }),

  methods: {
    formatPhone,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;

        const insuranceCompanyService = new InsuranceCompanyService();
        const { status, data } = await insuranceCompanyService.getContacts({
          page,
          take: itemsPerPage,
          insuranceCompanyDocument: this.$route.params.cnpj
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async deleteContact(item) {
      try {
        const result = await confirmMessage(
          'Atenção!',
          'Deseja realmente excluir o registro selecionado?'
        );

        if (result) {
          const insuranceCompanyService = new InsuranceCompanyService();
          const { status } = await insuranceCompanyService.deleteContact({
            id: item.id,
            document: this.$route.params.cnpj
          });

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    },

    create() {
      this.selectedItem = {};
      this.dialogEdit = false;
      this.dialogTitle = 'Adicionar contato';
      this.dialog = !this.dialog;
    },

    edit(item) {
      this.selectedItem = Object.assign({}, item);
      this.dialogEdit = true;
      this.dialogTitle = 'Visualizar contato';
      this.dialog = !this.dialog;
    },

    closeModal(search) {
      this.dialog = false;

      if (search) {
        this.search();
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
